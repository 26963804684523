const ENV = {
  // baseDmsUrl: '/',
  // baseApiEndpoint: 'http://localhost:2828/api/',
  // basePortalUrl: 'http://localhost:2828/',

  baseDmsUrl: 'https://qlpbgdpl.thi360.com/',
  basePortalUrl: 'https://pbgdpl.thi360.com/',
  baseApiEndpoint: 'https://qlpbgdpl.thi360.com/api/',

  // baseDmsUrl: 'https://qlpbgdpl.thanhhoa.gov.vn/',
  // basePortalUrl: 'https://pbgdpl.thanhhoa.gov.vn/',
  // baseApiEndpoint: 'https://qlpbgdpl.thanhhoa.gov.vn/api/', //edit

  clientId: '099153c2625149bc8ecb3e85e03f0022'
};

export default ENV;