import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      style={{width: '48px', height: '48px'}}
      src="/static/images/quochuy.png"
      {...props}
    />
  );
}

export default Logo;
