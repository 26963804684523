import React, { Fragment, lazy, Suspense } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="app/reports/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/account/:tab?',
        component: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        path: '/app/documents/:status?/:folderId?',
        exact: true,
        component: lazy(() => import('src/views/dms/Document'))
      },
      {
        path: '/app/documents/view/:id?',
        exact: true,
        component: lazy(() => import('src/views/pages/Document'))
      },
      {
        path: '/app/documentcontents/view/:id?',
        exact: true,
        component: lazy(() => import('src/views/pages/DocumentContent'))
      },
      {
        path: '/app/documentapprovals/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentWorkflowApprove'))
      },
      {
        path: '/app/documentpublishes/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentWorkflowPublish'))
      },
      {
        path: '/app/draftdocuments',
        exact: true,
        component: lazy(() => import('src/views/dms/DraftDocument'))
      },
      {
        path: '/app/syncdocuments',
        exact: true,
        component: lazy(() => import('src/views/dms/SyncDocument'))
      },
      {
        path: '/app/sorldocuments',
        exact: true,
        component: lazy(() => import('src/views/dms/SorlDocument'))
      },
      {
        path: '/app/documentcontents/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentContent'))
      },
      {
        path: '/app/documentcontentapprovals/:status?',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentContentWorkflowApprove')
        )
      },
      {
        path: '/app/documentcontentpublishes/:status?',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentContentWorkflowPublish')
        )
      },
      {
        path: '/app/documentdetailapprovals/:status?',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentDetailWorkflowApprove')
        )
      },
      {
        path: '/app/documentdetailpublishes/:status?',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentDetailWorkflowPublish')
        )
      },
      {
        path: '/app/documentdetails/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentDetail'))
      },
      {
        path: '/app/DocumentFieldCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentFieldCatalog'))
      },
      // Đơn vị phát hành văn bản
      {
        path: '/app/DocumentIssuerCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentIssuerCatalog'))
      },
      // Địa bàn
      {
        path: '/app/RegionCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/RegionCatalog'))
      },
      // Ngành nghề kinh doanh
      {
        path: '/app/BusinessLineCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/BusinessLineCatalog'))
      },
      // Ngành nghề kinh doanh
      {
        path: '/app/MarketCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/MarketCatalog'))
      },
      {
        path: '/app/DocumentContentGroupCatalog',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentContentGroupCatalog')
        )
      },
      {
        path: '/app/UserGroupCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/UserGroupCatalog'))
      },
      {
        path: '/app/DocumentStatusCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentStatusCatalog'))
      },
      {
        path: '/app/DocumentFolderCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentFolderCatalog'))
      },
      // Cơ quan quản lý
      {
        path: '/app/GovermentAgencyCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/GovermentAgencyCatalog'))
      },
      // Danh mục Nội dung Văn bản
      {
        path: '/app/DocumentContentCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentContentCatalog'))
      },
      // Loại Văn bản
      {
        path: '/app/DocumentTypeCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentTypeCatalog'))
      },
      // Chức danh người ký
      {
        path: '/app/SignerPositionCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/SignerPositionCatalog'))
      },
      // Nhòm tài liệu
      {
        path: '/app/DocumentGroupCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/ContentType'))
      },
      // Nhòm tài liệu
      {
        path: '/app/DocumentSignerCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentSignerCatalog'))
      },
      // Loại Văn bản
      {
        path: '/app/DocumentSubjectCatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/DocumentSubjectCatalog'))
      },
      // Template Nội dung Văn bản
      {
        path: '/app/documentcontenttemplates',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentContentTemplate/List')
        )
      },
      // Add/edit template Nội dung Văn bản
      {
        path: '/app/documentcontenttemplates/edit/:id',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentContentTemplate/Edit')
        )
      },
      // Template Văn bản liên quan
      {
        path: '/app/documentrelatedtemplates',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentRelatedTemplate/List')
        )
      },
      // Add/edit template Văn bản liên quan
      {
        path: '/app/documentrelatedtemplates/edit/:id',
        exact: true,
        component: lazy(() =>
          import('src/views/dms/DocumentRelatedTemplate/Edit')
        )
      },
      // Danh mục tin bài
      {
        path: '/app/newscatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/NewsCatalog'))
      },
      // Content type
      {
        path: '/app/contenttypes',
        exact: true,
        component: lazy(() => import('src/views/dms/ContentType'))
      },
      // SYSTEM
      // Đơn vị, phòng ban
      {
        path: '/app/departments',
        exact: true,
        component: lazy(() => import('src/views/dms/Department'))
      },
      // user
      {
        path: '/app/users',
        exact: true,
        component: lazy(() => import('src/views/dms/User/List'))
      },
      // permission
      {
        path: '/app/permissions',
        exact: true,
        component: lazy(() => import('src/views/dms/Permission'))
      },
      // Add/edit template Nội dung Văn bản
      {
        path: '/app/users/edit/:id',
        exact: true,
        component: lazy(() => import('src/views/dms/User/Edit'))
      },
      // Chức danh người ký
      {
        path: '/app/servicecatalogs',
        exact: true,
        component: lazy(() => import('src/views/dms/ServiceCatalog'))
      },
      // Hỏi đáp
      {
        path: '/app/qa/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/QA'))
      },
      // Slider
      {
        path: '/app/slider/',
        exact: true,
        component: lazy(() => import('src/views/dms/Slider'))
      },
      {
        path: '/app/subscribe/',
        exact: true,
        component: lazy(() => import('src/views/dms/Subscribe'))
      },
      // Tin bài
      {
        path: '/app/news/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/News'))
      },
      {
        path: '/app/newsapprovals/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/NewsWorkflowApprove'))
      },
      {
        path: '/app/newspublishes/:status?',
        exact: true,
        component: lazy(() => import('src/views/dms/NewsWorkflowPublish'))
      },
      // Ngân hàng câu hỏi
      {
        path: '/app/questions',
        exact: true,
        component: lazy(() => import('src/views/dms/Question'))
      },
      // Test
      {
        path: '/app/tests',
        exact: true,
        component: lazy(() => import('src/views/dms/Test'))
      },
      //Thống kê
      {
        path: '/app/stat',
        exact: true,
        component: lazy(() => import('src/views/dms/Stat'))
      },
      // Question Catalog
      {
        path: '/app/questioncatalog',
        exact: true,
        component: lazy(() => import('src/views/dms/QuestionCatalog'))
      },
      // Shortcut
      {
        path: '/app/shortcut',
        exact: true,
        component: lazy(() => import('src/views/dms/Shortcut'))
      },
      // Keywrod
      {
        path: '/app/keyword',
        exact: true,
        component: lazy(() => import('src/views/dms/Keyword'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
