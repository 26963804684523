import { store } from 'react-notifications-component';

class notifyManager {

  showSuccess = (message: string) => {
    store.addNotification({
      // title: "Thành công",
      message: message,
      type: "success",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  };

  showError = (message: string) => {
    store.addNotification({
      // title: "Lỗi",
      message: message,
      type: "warning",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  }

  showInfo = (message: string) => {
    store.addNotification({
      // title: "Thông tin",
      message: message,
      type: "info",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  }

  showSuccessUpdate = () => {
    store.addNotification({
      message: "Cập nhật thành công",
      type: "success",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  };

  showSuccessDelete = () => {
    store.addNotification({
      message: "Xóa thành công",
      type: "success",
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
      }
    });
  };
}

const instance = new notifyManager();
export default instance;