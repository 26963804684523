import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from 'src/actions/accountActions';
import authService from 'src/services/authService';
import Scope from './Scope';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

function Account() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [isOpenDepartmentSelector, setIsOpenDepartmentSelector] = useState(
    false
  );
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    setUserProfile(authService.getUserProfile());
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDepartmentSelector = () => {
    setIsOpenDepartmentSelector(!isOpenDepartmentSelector);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      window.location.href = '/login';
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} />
        {userProfile && (
          <Hidden smDown>
            <Typography variant="h6" color="inherit">
              {userProfile.UserName}
            </Typography>
          </Hidden>
        )}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account" onClick={handleClose}>
          Tài khoản
        </MenuItem> 
        {userProfile && userProfile.UserHasMultiScopePermission && (
          <MenuItem onClick={handleDepartmentSelector}>Đổi đơn vị</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Thoát</MenuItem>
      </Menu>
      {isOpenDepartmentSelector && <Scope onClose={handleDepartmentSelector} />}
    </>
  );
}

export default Account;
