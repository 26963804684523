import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { DocumentFolderCatalogApi } from 'src/api/catalog-api';
import Logo from 'src/components/Logo';
import authService from 'src/services/authService';

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import SubjectIcon from '@material-ui/icons/Subject';
import SyncIcon from '@material-ui/icons/Sync';

import NavItem from './NavItem';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const [profile, setProfile] = useState(null);
  const [folders, setFolders] = useState(null);
  const account = useSelector(state => state.account);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    setProfile(authService.getUserProfile());

    DocumentFolderCatalogApi.searches(null).then(response => {
      setFolders(response.data.Data);
    });
  }, [location.pathname]);

  const content = (
    <>
      {profile && (
        <Box height="100%" display="flex" flexDirection="column">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Hidden lgUp>
              <Box p={2} display="flex" justifyContent="center">
                <RouterLink to="/">
                  <Logo />
                </RouterLink>
              </Box>
            </Hidden>
            <Box p={2}>
              <List disablePadding>
                <NavItem
                  info=""
                  key="/app/reports/dashboard"
                  open
                  depth={0}
                  href="/app/reports/dashboard"
                  icon={HomeIcon}
                  title="Trang chủ"
                />
              </List>
              {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                profile.IS_DEPARTMENT_APPROVE_ADMIN ||
                profile.IS_GLOBAL_APPROVE_ADMIN ||
                profile.IS_PUBLISH_ADMIN ||
                profile.IS_GLOBAL_CONTENT_ADMIN) && (
                <List
                  key="QUẢN TRỊ NỘI DUNG"
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      QUẢN TRỊ NỘI DUNG
                    </ListSubheader>
                  }
                >
                  <NavItem
                    depth={0}
                    icon={DescriptionIcon}
                    title="Tin tức"
                    open
                  >
                    <List open>
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/news"
                          title="Quản lý Tin bài"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_APPROVE_ADMIN ||
                        (profile.IS_GLOBAL_APPROVE_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/newsapprovals"
                          title="Duyệt Tin bài"
                        />
                      )}
                      {((profile.IS_PUBLISH_ADMIN &&
                        (account.globalScope ||
                          profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/newspublishes"
                          title="Xuất bản Tin bài"
                        />
                      )}
                      {((profile.IS_DEPARTMENT_CONTENT_ADMIN &&
                        (account.globalScope ||
                          profile.UserHasMultiScopePermission == false) ===
                          false) ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/newscatalog"
                          title="Chuyên mục tin bài"
                        />
                      )}
                    </List>
                  </NavItem>
                  <NavItem
                    depth={0}
                    className=""
                    info=""
                    key="/app/reports/dashboard"
                    icon={LibraryBooksIcon}
                    title="Văn bản, Tài liệu gốc"
                    open
                  >
                    <List>
                      {folders && folders.length > 0 && (
                        <>
                          <NavItem
                            depth={1}
                            icon={FolderIcon}
                            href={'#'}
                            title="Thư mục"
                          >
                            <List>
                              {folders.map(item => (
                                <NavItem
                                  depth={1}
                                  href={`/app/documents/0/${item.Id}`}
                                  title={`${item.Name}`}
                                />
                              ))}
                            </List>
                          </NavItem>
                        </>
                      )}
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documents"
                          title="Quản lý Văn bản, tài liệu"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_APPROVE_ADMIN ||
                        (profile.IS_GLOBAL_APPROVE_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentapprovals"
                          title="Duyệt Văn bản, tài liệu"
                        />
                      )}
                      {((profile.IS_PUBLISH_ADMIN &&
                        (account.globalScope ||
                          profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentpublishes"
                          title="Xuất bản Văn bản, tài liệu"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentdetails"
                          title="Quản lý Bóc tách Văn bản"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_APPROVE_ADMIN ||
                        (profile.IS_GLOBAL_APPROVE_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentdetailapprovals"
                          title="Duyệt Bóc tách Văn bản"
                        />
                      )}
                      {((profile.IS_PUBLISH_ADMIN &&
                        (account.globalScope ||
                          profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentdetailpublishes"
                          title="Xuất bản Bóc tách Văn bản"
                        />
                      )}
                    </List>
                  </NavItem>
                  <NavItem
                    depth={0}
                    icon={SubjectIcon}
                    title="Biên tập Nội dung"
                    open
                  >
                    <List open>
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentcontents"
                          title="Quản lý Biên tập Nội dung"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_APPROVE_ADMIN ||
                        (profile.IS_GLOBAL_APPROVE_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentcontentapprovals"
                          title="Duyệt Biên tập Nội dung"
                        />
                      )}
                      {((profile.IS_PUBLISH_ADMIN &&
                        (account.globalScope ||
                          profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          depth={1}
                          href="/app/documentcontentpublishes"
                          title="Xuất bản Biên tập Nội dung"
                        />
                      )}
                    </List>
                  </NavItem>
                  <NavItem
                    depth={0}
                    icon={SyncIcon}
                    title="Đồng bộ Văn bản"
                    open
                  >
                    <List open>
                      {(profile.IsSuperUser ||
                        profile.IS_GLOBAL_CONTENT_ADMIN) && (
                        <>
                          <NavItem
                            depth={1}
                            href="/app/syncdocuments"
                            title="Đồng bộ Văn bản từ hệ thống QPPL"
                          />
                          <NavItem
                            depth={1}
                            href="/app/sorldocuments"
                            title="Đồng bộ Solr"
                          />
                        </>
                      )}
                    </List>
                  </NavItem>
                  <NavItem depth={0} icon={SchoolIcon} title="Trắc nghiệm" open>
                    <List open>
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/tests"
                          title="Bài trắc nghiệm"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/questions"
                          title="Ngân hàng câu hỏi"
                        />
                      )}
                      {(profile.IS_DEPARTMENT_CONTENT_ADMIN ||
                        (profile.IS_GLOBAL_CONTENT_ADMIN &&
                          (account.globalScope ||
                            profile.UserHasMultiScopePermission == false)) ||
                        profile.IsSuperUser) && (
                        <NavItem
                          open
                          depth={1}
                          href="/app/questioncatalog"
                          title="Danh mục câu hỏi"
                        />
                      )}
                    </List>
                  </NavItem>
                  <NavItem
                    depth={0}
                    icon={HelpOutlineIcon}
                    title="Hỏi đáp"
                    href="/app/qa"
                    open
                  ></NavItem>
                  <NavItem
                    depth={0}
                    icon={EmailIcon}
                    title="Email đăng ký"
                    href="/app/subscribe"
                    open
                  ></NavItem>
                  <NavItem
                    depth={0}
                    icon={OndemandVideoIcon}
                    title="Quản lý Slider"
                    href="/app/slider"
                    open
                  ></NavItem>
                </List>
              )}
              {(profile.IsSuperUser ||
                profile.IS_DEPARTMENT_ADMIN ||
                (profile.IS_GLOBAL_ADMIN &&
                  (account.globalScope ||
                    profile.UserHasMultiScopePermission == false))) && (
                <List
                  key="HỆ THỐNG"
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      HỆ THỐNG
                    </ListSubheader>
                  }
                >
                  <NavItem
                    depth={0}
                    icon={FolderIcon}
                    title="Quản lý Danh mục"
                    open
                  >
                    <List>
                      <NavItem
                        depth={1}
                        href="/app/DocumentFieldCatalog"
                        title="Lĩnh vực văn bản"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentFolderCatalog"
                        title="Thư mục Văn bản"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentStatusCatalog"
                        title="Trạng thái Văn bản"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentIssuerCatalog"
                        title="Đơn vị phát hành"
                      />
                      <NavItem
                        depth={1}
                        href="/app/SignerPositionCatalog"
                        title="Chức danh người ký"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentSubjectCatalog"
                        title="Chủ đề Văn bản"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentSignerCatalog"
                        title="Tác giả/ người ký Văn bản"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentContentCatalog"
                        title="Đối tượng sử dụng"
                      />
                      <NavItem
                        depth={1}
                        href="/app/BusinessLineCatalog"
                        title="Ngành nghề kinh doanh"
                      />
                      <NavItem
                        depth={1}
                        href="/app/GovermentAgencyCatalog"
                        title="Cấp quản lý"
                      />
                      <NavItem
                        depth={1}
                        href="/app/RegionCatalog"
                        title="Địa bàn"
                      />
                      <NavItem
                        depth={1}
                        href="/app/UserGroupCatalog"
                        title="Nhóm Người dùng"
                      />
                      <NavItem
                        depth={1}
                        href="/app/DocumentContentGroupCatalog"
                        title="Nhóm Tình huống pháp luật"
                      />
                      <NavItem
                        depth={1}
                        href="/app/MarketCatalog"
                        title="Danh mục Thị trường"
                      />
                    </List>
                  </NavItem>
                  {(profile.IsSuperUser ||
                    profile.IS_GLOBAL_ADMIN ||
                    profile.IS_DEPARTMENT_ADMIN) && (
                    <NavItem
                      depth={0}
                      icon={SettingsIcon}
                      title="Hệ thống"
                      open
                    >
                      <List>
                        {profile.IsSuperUser && (
                          <NavItem
                            depth={1}
                            href="/app/departments"
                            title="Đơn vị, phòng ban"
                          />
                        )}
                        {(profile.IsSuperUser ||
                          profile.IS_GLOBAL_ADMIN ||
                          profile.IS_DEPARTMENT_ADMIN) && (
                          <NavItem
                            depth={1}
                            href="/app/users"
                            title="Người dùng"
                          />
                        )}
                        {(profile.IsSuperUser || profile.IS_GLOBAL_ADMIN) && (
                          <NavItem
                            depth={1}
                            href="/app/permissions"
                            title="Phân quyền"
                          />
                        )}
                        {profile.IsSuperUser && (
                          <>
                            <NavItem
                              depth={1}
                              href="/app/servicecatalogs"
                              title="Dịch vụ"
                            />
                            <NavItem
                              depth={1}
                              href="/app/documentcontenttemplates"
                              title="Template Biên tập nội dung"
                            />
                            {/* <NavItem
                              depth={1}
                              href="/app/contenttypes"
                              title="Loại Văn bản, tài liệu gốc"
                            /> */}
                            <NavItem
                              depth={1}
                              href="/app/DocumentTypeCatalog"
                              title="Loại Văn bản, Tài liệu gốc"
                            />

                            <NavItem
                              depth={1}
                              href="/app/shortcut"
                              title="Shortcut"
                            />

                            <NavItem
                              depth={1}
                              href="/app/keyword"
                              title="Từ khóa"
                            />
                          </>
                        )}
                      </List>
                    </NavItem>
                  )}
                  {(profile.IsSuperUser ||
                    profile.IS_GLOBAL_ADMIN ||
                    profile.IS_DEPARTMENT_ADMIN) && (
                    <NavItem
                      depth={0}
                      icon={DashboardOutlined}
                      title="Thống kê"
                      open
                    >
                      <List>
                        <NavItem
                          depth={1}
                          href="/app/stat"
                          title="Thống kê tin bài"
                        />
                      </List>
                    </NavItem>
                  )}
                </List>
              )}
            </Box>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  );
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
