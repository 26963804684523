import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import Notifications from './Notifications';
import Search from './Search';
import authService from 'src/services/authService';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const [userProfile, setUserProfile] = useState(null);
  const location = useLocation();
  const account = useSelector(state => state.account);

  useEffect(() => {
    setUserProfile(authService.getUserProfile());
    console.log(userProfile);
  }, [location.pathname]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Hidden>
        <h3
          style={{
            float: 'left',
            textTransform: 'uppercase',
            paddingLeft: '10px'
          }}
        >
          HỆ THỐNG THÔNG TIN PHỔ BIẾN GIÁO DỤC PHÁP LUẬT
          <br />
          {userProfile &&
            userProfile.UserHasMultiScopePermission &&
            account.globalScope == false && <>{userProfile.DepartmentName}</>}
          {userProfile && !userProfile.UserHasMultiScopePermission && (
            <>{userProfile.DepartmentName}</>
          )}
          {userProfile &&
            (account.globalScope == true ||
              userProfile.DepartmentName == null) && <>Tỉnh Thanh Hóa</>}
        </h3>
        <Box ml={2} flexGrow={1} />
        {/* <Search /> */}
        {/* <Contacts /> */}
        <Notifications />
        {/* <Settings /> */}
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
