import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  Typography
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { useEffect, useState } from 'react';
import { XCircle as XIcon } from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import authService from 'src/services/authService';
import { toggleMode } from 'src/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import notifyManager from 'src/utils/notifyManager';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

function Scope({ onClose }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [userProfile, setUserProfile] = useState(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const history = useHistory();

  useEffect(() => {
    const userProfile = authService.getUserProfile();
    setUserProfile(userProfile);
    if (account.globalScope) {
      setValue(0);
    } else {
      setValue(userProfile.DepartmentId);
    }
  }, [account]);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleToggle = async () => {
    await dispatch(toggleMode());
    history.push('/');
    notifyManager.showSuccess("Bạn đã đổi đơn vị");
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          {userProfile && (
            <Box p={3}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4" color="textPrimary">
                  CHỌN ĐƠN VỊ
                </Typography>
                <IconButton onClick={handleClose}>
                  <SvgIcon fontSize="small">
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
              <Box mt={2}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Đơn vị
                  </InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={handleChange}
                    label="Đơn vị"
                  >
                    <MenuItem value={0}>Đơn vị cao nhất</MenuItem>
                    <MenuItem value={userProfile.DepartmentId}>
                      {userProfile.DepartmentName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {value === 0 && (
                <Box mt={4}>
                  <Grid container spacing={1}>
                    {userProfile.IsSuperUser && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Super admin"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_GLOBAL_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Quản trị cao nhất"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_GLOBAL_CONTENT_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Quản trị Nội dung cao nhất"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_GLOBAL_APPROVE_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Kiểm duyệt Nội dung cao nhất"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_PUBLISH_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Xuất bản Nội dung"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
              {value > 0 && (
                <Box mt={4}>
                  <Grid container spacing={1}>
                    {userProfile.IS_DEPARTMENT_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Quản trị cao nhất Đơn vị"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_DEPARTMENT_CONTENT_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Quản trị Nội dung của Đơn vị"
                        />
                      </Grid>
                    )}
                    {userProfile.IS_DEPARTMENT_APPROVE_ADMIN && (
                      <Grid item xs={12} md={12}>
                        <FormControlLabel
                          fullWidth
                          control={
                            <Checkbox inputProps={{ readOnly: true }} checked />
                          }
                          label="Kiểm duyệt Nội dung của Đơn vị"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={
                    (account.globalScope && value === 0) ||
                    (!account.globalScope && value > 0)
                  }
                  onClick={handleToggle}
                >
                  <SendIcon />
                  &nbsp; Đổi đơn vị
                </Button>
              </Box>
            </Box>
          )}
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

export default Scope;
