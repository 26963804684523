import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserNotificationApi from 'src/api/user-notification-api';
import UsertNotification from 'src/interfaces/UserNotification';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { getNotifications } from 'src/actions/notificationsActions';
import Badge from '@material-ui/core/Badge';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

function Notifications() {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    var api = new UserNotificationApi();
    api.scan().then(response => {});
    api.searches({ Status: 0 }).then(response => {
      setNotifications(response.data.Data);
      setCount(response.data.Data.length);
    });
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000*5);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <Badge badgeContent={count} color="error">
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h4" color="textPrimary">
            Thông báo
          </Typography>
        </Box>
        {!notifications || notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="p" color="textPrimary">
              Hiện tại chưa có thông báo mới nào.
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.map(notification => {
                return (
                  <ListItem
                    className={classes.listItem}
                    component={RouterLink}
                    divider
                    key={notification.Id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <SvgIcon fontSize="small">
                          <BellIcon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.Name}
                      primaryTypographyProps={{
                        variant: 'subtitle2',
                        color: 'textPrimary'
                      }}
                      secondary={notification.ShortContent}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
        <Box p={1} display="flex" justifyContent="center">
          <Button
            dense
            component={RouterLink}
            onClick={handleClose}
            size="small"
            to="/app/account/notification"
          >
            Xem tất cả
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default Notifications;
